import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-servicefooter',
  templateUrl: './servicefooter.component.html',
  styleUrls: ['./servicefooter.component.scss']
})
export class ServicefooterComponent implements OnInit {

  closeResult: string;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  viewpopup(policy:any){
    this.modalService.open(policy, { ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result)=>{
      this.closeResult = `closed With: ${result}`;
    },(reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
