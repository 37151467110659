import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OneclickInterceptor } from './core/helpers/oneclick.interceptor';
import { LandingMainComponent } from './account/auth/landing-main/landing-main.component';
import { ServiceslistComponent } from './oneclickservice/serviceslist/serviceslist.component';
import { ServicenameComponent } from './oneclickservice/servicename/servicename.component';

const routes: Routes = [
  { path: '', component:ServiceslistComponent },
  { path: 'servicelist', component:ServiceslistComponent },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  //tslint:disable-next-line: max-line-length
  { path: 'page', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), 
     canActivate: [AuthGuard]
   },
  { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), 
     canActivate: [AuthGuard] 
  },
  // {path:'service',component:ServiceslistComponent},
  {path : 'service/servicename',component:ServicenameComponent},
  { path: '**', component: Page404Component },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OneclickInterceptor,
      multi: true
    }
  ]
})

export class AppRoutingModule { }
