<header id="page-topbar">
  <div class="navbar-header" style="height: 71px;">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.svg" alt="">
          </span>
          <!-- <span class="logo-lg">
            <img src="assets/images/users/1652869737440.png" alt="" height style="width: 217px;height: 88px; margin-top: -1px;margin-left: -32px;
            ">
          </span> -->
          <span class="logo-lg">
            <img src="assets/Logo-01.png" alt="" height style=" width: 210px;
              height: 138px;
              margin-top: -12px;
              margin-left: -25px;
             ">
          </span>

          <!-- <span class="logo-sm">
            <span><h1 class="topbar">O</h1></span>
          </span>
          <span class="logo-lg">
            <span><h1 class="topbar" style="width: 150px;">OneClick</h1></span>
          </span>
        </a> -->
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        style="margin-left: -25px;margin-top: 31px;" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        style="margin-left: -25px;margin-top: 10px;" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button> -->

      <!-- App Search-->
      <form class="app-search d-none d-xl-block" [formGroup]="searchForm">
        <div class="position-relative" style="margin-bottom: 13px;">
          <input type="text" class="form-control searchBox" placeholder="Search Request ID" formControlName="search"
            [(ngModel)]="reqID" [ngClass]="{ 'is-invalid': submitted && f.search.errors }" (keyup.enter)="onEnter()" />
          <!-- <div *ngIf="submitted && f.search.errors" class="invalid-feedback">
            <div *ngIf="f.search.errors.required">Request ID is required</div>
            </div> -->
          <span class="bx bx-search-alt"></span>
        </div>
      </form>

      <!-- <div class="searchBtn">
    <button type="button" class="btn btn-info" (click)="search()">Search</button>
    </div> -->

      <!-- <div *ngIf="workspacename?.length > 0" id="workshop">
        <h4 id="shop">{{workspacename}}</h4>
      </div> -->

      <!-- <div class="dropdown-mega d-none d-lg-block ms-2" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="false"
          aria-expanded="false">
          {{ 'HEADER.MEGA_MENU' | translate}}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col-sm-8"> -->

      <!-- <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0"> {{ 'HEADER.UI.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.APPLICATIONS.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.ECOMMERCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CALENDAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.EMAIL' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.PROJECTS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.TASKS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CONTACTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.EXTRA_PAGES.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.LIGHT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMPACT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.MAINTENANCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMING SOON' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.TIMELINE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.FAQ' | translate}}</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0"> {{ 'HEADER.UI.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img src="assets/images/megamenu-img.png" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="d-flex">
      <div class="notification-bell">
        <i class="fas fa-bell" (click)="openModal(content)" style="width: 80%; cursor: pointer;"></i>
        <span class="reminder-count">{{Count + userCount}}</span>
      </div>
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">


          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div><span>

        <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
         item
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
         item
        </div>
      </div> -->

        <!-- <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
          <i class="bx bx-customize"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" ngbDropdownMenu>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/github.png" alt="Github">
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble">
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox">
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack">
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
        <br>

        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
              <div class="page-title-right">
                <h4 class="mb-0 font-size-18">
                  {{date | date:' d/ MMM / y'}}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </span>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">3</span>
        </button>

        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>

          <ngx-simplebar style="position: relative; height: 230px;">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>

            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>

            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>

            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>

          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{{
                'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}</span>
            </a>
          </div>
        </div>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/users/user img.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{uname?.firstName}} {{uname?.lastName}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/page/contacts/profile"><i
              class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-wallet font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a> -->
          <!-- <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-1"></i>{{
            'HEADER.LOGIN.SETTINGS' | translate}} </a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>
      &nbsp;&nbsp;
      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->

    </div>
  </div>
</header>
<ng-template #content let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title">Modal Title</h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
  <div class="modal-body">

    <div class="date-picker">
      <input type="date" #datePicker (change)="getallRemainderDate(datePicker.value)" [value]="getCurrentDate()">
    </div>

    <div class="spinner">
      <div *ngIf='isloading' class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="d-flex justify-content-center mb-2" *ngIf="reminderDetails?.length > 0 && !isloading">
      <h3>Request Reminder details</h3>
    </div>

    <div *ngIf="reminderDetails?.length > 0 && !isloading">
      <table class="table table-bordered fixed">
        <thead class="thead-dark">
          <tr>
            <th data-name="requestID" scope="col" class="reqID">Request Id</th>
            <th data-name="requestName" scope="col" class="reqtime">Request Name</th>
            <th data-name="customerName" scope="col" class="cusName">Customer Name</th>
            <th data-name="customerMobile" scope="col" class="mobile">Customer Mobile</th>
            <th data-name="remainderDate" scope="col" class="mobile">Reminder Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of reminderDetails | paginate : {itemsPerPage: requestCount, currentPage: requestPage}">
            <td class="left">
              <a (click)="modal.close('Save click')" (click)="marknotificationasread(item.id)"
                [routerLink]="['/page/tracking']" [queryParams]="{RequestID: item?.id}">
                {{item?.requestID}}
              </a>
            </td>
            <td class="center">{{item?.requestName}}</td>
            <td class="center">{{item?.customerName}}</td>
            <td class="center">{{item?.customerMobile}}</td>
            <td class="center">{{item?.remainderDate| date: 'MM/dd/yyyy'}}</td>

          </tr>
        </tbody>

      </table>
    </div>
    <div class="text-center mt-3 mb-3" *ngIf="!reminderDetails && !isloading">
      <h4> Request reminder details not found </h4>
    </div>

    <div class="text-right" *ngIf="reminderDetails && !isloading">
      <pagination-controls (pageChange)="requestPage = $event"></pagination-controls>
    </div>    

    <div class="d-flex justify-content-center mb-2" *ngIf="userremainder?.length > 0 && !isloading">
      <h3>User Reminder details</h3>
    </div>

    <div *ngIf="userremainder?.length > 0 && !isloading">
      <table class="table table-bordered fixed">
        <thead class="thead-dark">
          <tr>
            <th data-name="userID" scope="col" class="reqID">User Id</th>
            <th data-name="userName" scope="col" class="reqtime">User Name</th>
            <th data-name="mobile" scope="col" class="cusName">Mobile</th>
            <th data-name="calledDate" scope="col" class="mobile">Called Date</th>
            <th data-name="remainderDate" scope="col" class="mobile">Reminder Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of userremainder | paginate : {itemsPerPage: usersCount, currentPage: userPage, id:'some_id1'}">
            <td class="left">
              <a (click)="modal.close('Save click')" (click)="usernotificationasread(item.userID, viewUser)"
                [routerLink]="['/page/allusers']">
                {{item?.userID}}
              </a>
            </td>
            <td class="center">{{item?.userName}}</td>
            <td class="center">{{item?.mobile}}</td>
            <td class="center">{{item?.calledDate | date: 'MM/dd/yyyy'}}</td>
            <td class="center">{{item?.remainderDate| date: 'MM/dd/yyyy'}}</td>
          </tr>
        </tbody>

      </table>

    </div>
    <div class="text-center mt-3 mb-3" *ngIf="!userremainder && !isloading">
      <h4> User reminder details not found </h4>
    </div>

    <div class="text-right" *ngIf="userremainder && !isloading">
      <pagination-controls id="some_id1" (pageChange)="userPage = $event"></pagination-controls>
    </div>
  </div>
 

</ng-template>

<ng-template #viewUser let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="spinner">
    <div *ngIf='isloading' class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  
  <div class="modal-body" *ngIf="viewUserId && !isloading">
    <div class="text-center">
      <h3 class="text-primary">View Reminder Details</h3>
    </div>
    <table class="table table-bordered fixed">
      <thead class="thead-dark">
        <tr>
          <th data-name="sNo" scope="col">S.No</th>
          <th data-name="userId" scope="col">User Id</th>
          <th data-name="description" scope="col">Description</th>
          <th data-name="calledDate" scope="col">Called Date</th>
          <th data-name="remainderDate" scope="col">Remainder Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of viewUserId | paginate: { itemsPerPage: 5, currentPage: page , id:'some_id2'} let i = index ">
          <td class="center">{{i + 1}}</td>
          <td class="center">{{item.userID}}</td>
          <td class="center">{{item.description}}</td>
          <td class="center">{{item.createdDate | date :'d,MMMM, y (h:mm a)'}}</td>
          <td class="center">{{item.remainderDate | date :'d,MMMM, y'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-center mt-3 mb-3" *ngIf="!viewUserId && !isloading">
    <h4>Details not found</h4>
  </div>
  <div class="text-right" *ngIf="viewUserId?.length > 0 && !isloading">
    <pagination-controls id="some_id2" (pageChange)="page = $event"></pagination-controls>
  </div>
</ng-template>