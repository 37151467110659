import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AllservicesService } from 'src/app/services/allservices.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-serviceslist',
  templateUrl: './serviceslist.component.html',
  styleUrls: ['./serviceslist.component.scss']
})
export class ServiceslistComponent {
  name: any
  serviceList: any;
  popularservices: any;
  showmore:boolean = false;
  loading:boolean=false;
  p2=1;
  servicesLimt = 8;
  id : number;
  servicecategory:any;

  constructor(private servicecat: AllservicesService, private route: Router,private modalService: NgbModal) { }
  ngOnInit(): void {
    this.loading=true;
    this.servicecat.getservicecatliist().subscribe(
      res => {
        this.serviceList = res['result']
        this.loading=false;
        
      }
    )
    this.loading=true;
    this.servicecat.getpopularservices().subscribe(
      res => {
        this.popularservices = res['result']
        this.loading=false;
        
      }
    )

  }
  //   serviceList=
  //   [
  //     {id: 1, service: 'Cleaning',img:"assets/IMG-20221216-WA0001.jpg"},
  //     {id: 2, service: 'Repair',img:"assets/IMG-20221216-WA0003.jpg"},
  //     {id: 3, service: 'Shifting Service', img:"assets/IMG-20221216-WA0004.jpg"},
  //     {id: 4, service: 'Pest Control Service', img:"assets/IMG-20221216-WA0005.jpg" }
  // ];

  serviceCategory(service) {
    this.route.navigate(['service/servicename'],
      { queryParams: { category: service.serviceCatName, id: service.id } }
    )
  }

  PopularService(service) {
    this.route.navigate(['service/servicename'],
      { queryParams: { servicename: service.serviceName, id: service.id } }
    )
  }
  onShowMoreClick() {
    // this.showmore = true;
    this.servicesLimt = 100;
    this.showmore=true;
  }
  onShowlessClick() {
    this.servicesLimt = 8;
    this.showmore=false;
  }
  
  public async service(content:any,id:number):Promise<void>{
    await this.serviceview(id);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',
    size: 'mysize', windowClass: 'my-class'
  });
    
  }
  public serviceview(id:number):Promise<void>{
    this.loading=true;
    return new Promise<void>((resolve, reject) => {
   this.servicecat.getpopularservicesbyid(id).subscribe((res)=>{

    this.servicecategory = res['result']
    this.loading=false;
    resolve();
  }, (error) => {
    reject(error);
  });
    
    })
   
    
}
}
