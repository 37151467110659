import { Component, OnInit, Output, EventEmitter, Inject,Input, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { UserserviceService } from 'src/app/services/userservice.service';
import { FormBuilder, FormGroup, Validators,ReactiveFormsModule,FormsModule  } from '@angular/forms';
import { HelperService, TriggerOption } from 'src/app/services/helper.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  page: any = 1;
  requestPage: any = 1;
  requestCount:any = 3;
  userPage: any = 1;
  usersCount:any = 3;
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  uname: any;
  result1: any;
  workspacename: string;
  date: Date;
  searchForm: FormGroup;
  gotReqID: any;
  reqID: any;
  submitted: boolean;
  reminderDetails: any[]=[];
  stagesList = [];
  Count: number = 0;
  readnotification: any[]=[];
  usernotification: any[]=[];
  userremainder: any[]=[];
  closeResult : string;
  userCount: number=0;
  remainderdate:any;
  currentDate: string;
  requestdate:  any[]=[];
  selectedDate: string;
  isloading: boolean = false;
  viewUserId: any[]=[];
  
  constructor(@Inject(DOCUMENT) private document: any, private router: Router,
   private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private user: UserserviceService,
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private modalService: NgbModal,
    private notificationService: NotificationService) 
    {  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.user.getid().subscribe(data => {
      this.uname = data?.["result"];
    })

    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required],
    });

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    this.workspacename = localStorage.getItem("workspaceName");


     this.date = new Date();
     //this.searchForm.reset();  
     this.getCurrentDate()
     this.getallRequestRemaindersDate();
     this.getalluserremaindersbydate();
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    // if (environment.defaultauth === 'firebase') {
    //   this.authService.logout();
    // } else {
    //   this.authFackservice.logout();
    // }
    this.user.logout();
    this.router.navigate(['/account/login']);
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  
  get f() { return this.searchForm.controls; }

  search(){
    this.submitted = true;
    if(this.searchForm.invalid)
    return;

    const data: TriggerOption = {
      value: this.reqID,
    };
    this.router.navigate(
      ['/page/tracking'],
      { queryParams: { RequestID: this.reqID } }
    );
     this.helper.emitData(data);
      
     this.submitted = false;
     this.searchForm.reset();  
  }

  onEnter() {
    this.search();
  }
  openModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;


    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });     
    this.getalluserremaindersbydate();
    this.getallRequestRemaindersDate();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  marknotificationasread(id:any){
    this.notificationService.Marknotificationasread(id).subscribe((res)=>{
      this.readnotification = res['result']
      this.getallRequestRemaindersDate();
    })
  }

  getallRequestRemaindersDate(){  
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    this.remainderdate = `${month}-${day}-${year}`;   
    this.notificationService.getallrequestremaindersbydate(this.remainderdate).subscribe((res)=>{
      this.reminderDetails = res['result']      
      this.Count = this.reminderDetails?.length;
      if (this.reminderDetails) {
        this.Count = this.reminderDetails.length;
      } else {
        this.Count = 0; 
      }      
    })
  }

  usernotificationasread(userid, viewUser){  
    this.getUserDescriptionsByUserId(userid);
    this.viewPopup(viewUser);
    this.notificationService.UserNotificationasread(userid).subscribe((res)=>{
      this.usernotification = res['result']
      this.getalluserremaindersbydate();      
    })
  }

  getalluserremaindersbydate(){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    this.remainderdate = `${month}-${day}-${year}`;
    this.notificationService.getallUserRemaindersbydate(this.remainderdate).subscribe((res)=>{
      this.userremainder = res['result']
      this.userCount = this.userremainder?.length;   
      if(this.userremainder){
        this.userCount= this.userremainder.length;
      }
      else{
        this.userCount = 0;
      }
    })
  }

  getallRequestRemaindersbyDate(selectedDate: string){  
    this.isloading = true;  
    this.notificationService.getallrequestremaindersbydate(selectedDate).subscribe((res)=>{
      this.reminderDetails = res['result']
      this.isloading = false;
    })
  }

getallUserRemainderDate(selectedDate: string){
  this.isloading = true;
  this.notificationService.getallUserRemaindersbydate(selectedDate).subscribe((res)=>{
    this.userremainder = res['result']    
    this.isloading = false;
  })
}

  getallRemainderDate(selectedDate: string){   
    this.getallRequestRemaindersbyDate(selectedDate);
    this.getallUserRemainderDate(selectedDate);
  }

  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getUserDescriptionsByUserId(id: any){
    this.isloading = true;
    this.notificationService.getallUserDescriptionbyuserid(id).subscribe((res)=>{
      this.viewUserId = res['result']
      this.isloading = false;
    })  
    this.getalluserremaindersbydate();
  }

  viewPopup(viewUser:any){
    this.modalService.open(viewUser, { ariaLabelledBy: 'modal-basic-title' , size: 'lg'}).result.then((result)=>{
      this.closeResult = `closed With: ${result}`;
    }, (reason)=>{
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });    
  }
}
