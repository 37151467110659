<app-servicetopbar></app-servicetopbar>


<div *ngIf="loading" class="text-center mt-3">
    <div style="color: blue;" class="loader">Loading...</div>
</div>


<div *ngIf="!loading">
    <div class="back">
        <div id="slider">
            <figure>
                <img src="../../../assets/SlideImage1.jpg">
                <img src="../../../assets/SlideImage2.jpg">
                <img src='../../../assets/SlideImage3.png'>
                <img src="../../../assets/SlideImage4.jpg">
                <img src="../../../assets/SlideImage1.jpg">
            </figure>
        </div>
    </div>

    <div id="service">
        <div class="Grid">

            <div *ngFor="let item of serviceList | slice:0:servicesLimt" class="CategoryService">
                <div type="button" class="a" (click)="service(content,item.id)">
                    <div class="img-container" [style.background-color]="item.backgroundColor">
                        <img src="{{item.image}}" class="im"><br><br>
                    </div>
                    <div style="text-align: center;">
                        <div class="fo">{{item.serviceCatName}}</div><br><br>
                    </div>

                    <!-- <img class="icon" src="../../../assets/Group 62.png"> -->
                </div>

            </div>

            <div *ngIf="!showmore">
                <div class="CategoryService">
                    <div type="button" (click)="onShowMoreClick()" class="a">
                        <div class="img-container" style="background-color: #CCE3EB ">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                    <circle cx="8" cy="8" r="4" fill="blue" />
                                    <circle cx="6" cy="8" r="0.5" fill="white" />
                                    <circle cx="8" cy="8" r="0.5" fill="white" />
                                    <circle cx="10" cy="8" r="0.5" fill="white" />
                                </svg>
                            </div>
                        </div>
                        <div style="text-align: center;">
                            <div class="fo">More</div><br><br>
                        </div>
                    </div>
                </div>

            </div>

            <div *ngIf="showmore">
                <div class="Grid1">
                    <div *ngFor="let item of serviceList|slice:servicesLimt:7" class="CategoryService">
                        <div type="button" class="a" (click)="serviceCategory(item)">
                            <div class="img-container" [style.background-color]="item.backgroundColor">
                                <img src="{{item.image}}" class="im"><br><br>
                            </div>
                            <div>
                                <div class="fo">{{item.serviceCatName}}</div><br><br>
                            </div>
                        </div>
                    </div>
                    <div class="CategoryService">
                        <div type="button" (click)="onShowlessClick()" class="a">
                            <div class="img-container" style="background-color: #CCE3EB">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="leftarrow">
                                        <path
                                            d="M12.8 7.2H2.69l3.54-3.54a.75.75 0 1 0-1.06-1.06l-5.3 5.3a.75.75 0 0 0 0 1.06l5.3 5.3a.75.75 0 0 0 1.06-1.06L2.69 8.8h10.11a.75.75 0 0 0 0-1.6"
                                            style="stroke-width:2;"></path>

                                    </svg>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>


            </div>
            <!-- border -->
            <div class="bor container">

            </div>
        </div>

    </div>

    <ng-template #content let-modal>
        <div>
            <div class="modal-body">

                <div class="row" *ngFor="let item of servicecategory ">
                    <div class="col-9">
                        <a type="button" class="pop" (click)="modal.close()" (click)="serviceCategory(item)"><b>{{
                                item.serviceName}} </b></a>
                    </div>
                    <div class="col-3">
                        <a (click)="serviceCategory(item)">
                            <i class="fa fa-chevron-right" (click)="modal.close()"></i>
                        </a>
                    </div>
                    <!-- border -->
                    <div class="bor container">

                    </div>
                </div>

            </div>
        </div>
    </ng-template>

    <div *ngIf="!loading">
        <div id="service-detail">
            <div class="container" style="position:relative; top:-2rem">
                <div>
                    <h2 class="Title">POPULER SERVICES</h2>
                </div>
                <div class="popularservice">
                    <div class="d-flex flex-wrap full">
                        <div *ngFor="let item1 of popularservices | paginate: { itemsPerPage: 8, currentPage: p2 }"
                            class="fl d-flex">
                            <div>
                                <img class="ima" src="{{item1.image}}"><br><br>
                                <div class="fo">{{item1.serviceName}}</div><br><br>
                                <!-- <img class="icon" src="../../../assets/Group 62.png"> -->
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center p-2">
                        <div class="text-right">
                            <pagination-controls (pageChange)="p2 = $event"></pagination-controls>
                        </div>
                    </div>
                    <!-- border -->
                    <div class="bor container">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="display: flex; justify-content: center;">
        <div class="poster">
            <div class="Grid2">
                <img src="../../../assets/AdPoster/Dec 2 (1).png" class="post-img">
                <img src="../../../assets/AdPoster/Nov 3 (2).png" class="post-img">
                <img src="../../../assets/AdPoster/Nov 6 (2).png" class="post-img">
            </div>
        </div>
    </div>

    <app-servicefooter></app-servicefooter>
</div>