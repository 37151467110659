<app-servicetopbar></app-servicetopbar> 




<div *ngIf="loading" class="text-center mt-3">
    <div style="color: blue;" class="loader">Loading...</div>
</div>

<div *ngIf="!loading">
    <div>
        <div *ngIf="NotFound">
            <h1 class="NotFound">No Service Found</h1>
        </div>

        <div *ngIf="!NotFound" class="">


            <div class="MainCOntent">
                <div class="row">
                    <div class="col-6">
                        <div class="SShow">
                            <div>
                                <div class="wrapper">
                                    <img src="{{servicebyid.image}}" width="500px" height="300px" />
                                </div>
                            </div><br><br>
                        </div>
                    </div>
                    <div class="col-6 scroll">
                        <div class="cleaning-di">
                            <div>
                                <h1><b>{{servicebyid.serviceName}}</b></h1>
                            </div>
                            <div>
                                <p>{{servicebyid.description1}}</p>
                            </div>
                            <div class="b2">
                                <button type="button" class="btn btn-info mr-2" (click)="bookreq(content4)">
                                    Book Now</button>
                            </div>
                        </div>
                        <!-- Modal -->
                        <ng-template #content4 let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">Book Request</h4>
                                <button type="button" class="btn-close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"></button>
                            </div>
                            <div class="modal-body">
                                <form [formGroup]="Reqbook">
                                    <div class="row">
                                        <div class="col-xl-11">
                                            <div class="mb-3">
                                                <div class="has-float-label">
                                                    <label for="name">Name </label>
                                                    <input class="form-control" type="text" formControlName="name"
                                                        placeholder="Enter Your Name" id="mobile" />
                                                </div>
                                                <!-- <small style="color: red;" *ngIf="name.invalid && name.touched">Name is required</small>
                                <small style="color: red;" *ngIf=" name.errors.minLength && name.untouched">Name should be in more than 3 digits</small>
    -->
                                            </div>
                                            <div class="mb-3">
                                                <div class="has-float-label">
                                                    <label for="mobile">Mobile </label>
                                                    <input class="form-control" type="text"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                        onKeyPress="if(this.value.length==10) return false"
                                                        formControlName="mobile" placeholder="Enter Mobile Number"
                                                        id="mobile" />
                                                </div>
                                                <div>
                                                    <small style="color: red;"
                                                        *ngIf="mobile && mobile?.errors?.required && mobile.touched">
                                                        Mobile number should be 10 digit</small>
                                                    <small style="color: red;"
                                                        *ngIf="mobile?.errors?.minlength && mobile.touched">
                                                        Mobile number should be 10 digits</small>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <div class="has-float-label">
                                                    <label for="ServiceName">Service Name </label>
                                                    <input class="form-control" type="text"
                                                        formControlName="RequestName" id="RequestName" />
                                                </div>
                                                <div>
                                                    <small style="color: red;"
                                                        *ngIf="RequestName && RequestName?.errors?.required && RequestName.touched">
                                                        service name is required</small>
                                                    <small style="color: red;"
                                                        *ngIf="RequestName?.errors?.minlength && RequestName.touched">Feild
                                                        should be in more than 5 digits</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="d-flex ">
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger"
                                        (click)="modal.dismiss(Reqbook)">Cancel</button>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" [disabled]="Reqbook.invalid" class="btn btn-primary"
                                        (click)="modal.close(Reqbook)">Book Now</button>
                                </div>

                            </div>
                        </ng-template>
                        <!-- <span class="Span">
                            {{serviceCategory.description}}
                        </span>  -->
                    </div><br><br>
                    <hr><br><br>
                    <div class="header">
                        <h2 style="color:lightskyblue;">Service details</h2>
                        <div class="cleaning-dis">
                            <div>
                                <ul class="para">
                                    <li *ngFor="let element of servicebyid.description2.split('.')">
                                        <p>{{element}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div><br><br>
                    <hr><br><br>
                    <div>
                        <h2 style="color:lightskyblue;">Service images</h2><br><br>
                        <div>
                            <div>
                                <img  src="{{servicebyid.image}}" width="200px" height="150px"/> <br><br>
                            </div> 
                        </div>
                    </div><br><br>

                    <hr><br><br>
                    <div>
                        <h2 style="color:lightskyblue;">Other services</h2><br><br>
                        <div class="d-flex">
                            <div class="justify-content-center" *ngFor="let item of popularservices?.slice(0, itemsToShow) ">
                                <img src="{{item.image}}" width="200px" height="150px" />  <br>                         
                            
                                <div class="justify-content-center">
                                   {{item.serviceName}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            
        <!-- <ngb-carousel interval="10000">
                <ng-template *ngFor="let item of serviceCategory.services" ngbSlide>
                    <div class="wrapper">
                        <img src="{{item.image}}" width="100%" height="100%" />
                    </div>
                </ng-template>
            </ngb-carousel> -->

             </div> 

        <!-- border -->
        <!-- <div class="bor container">

            </div>
            <div>
                <h2 class="Title">Services List</h2>
            </div> -->
    </div>
    <app-servicefooter></app-servicefooter>
</div>