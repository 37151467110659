import { Component } from '@angular/core';

@Component({
  selector: 'app-servicebooking',
  templateUrl: './servicebooking.component.html',
  styleUrls: ['./servicebooking.component.scss']
})
export class ServicebookingComponent {

}
