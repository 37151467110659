<!-- Navbar -->
<!-- <div class="container topbar"> -->
<!-- <div type="button">
        <img src="../../../assets/Asset_1_72x-100-removebg-preview.png" routerLink="/servicelist" alt=""
            type="button" loading="lazy" class="logo" />
    </div>
    <div class="quots">
        <p class="font"><b>One stop destination for all our service needs</b></p>
    </div> -->

<!-- <div class="collapse navbar-collapse" id="navbarButtonsExample" mdbCollapse style="position: relative;
bottom: 20px;margin-top: 27px;"> -->
<!-- Left links -->
<!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item"> -->
<!-- <a class="nav-link" href="#">Dashboard</a> -->
<!-- </li>
        </ul> -->
<!-- Left links -->


<!-- <div class="d-flex align-items-center" style="position: relative;left: 75px;">
            <div class="px-3 me-2">

                <a href="https://www.google.com/maps/place/Kailasam+Chinnakkanni+Mandabam/@12.4696211,78.536007,19z/data=!3m1!4b1!4m6!3m5!1s0x3bac53e76752df8b:0x158840e2410f8416!8m2!3d12.4696198!4d78.5365542!16s%2Fg%2F11c5xm9ll9"
                    style="color: black;" class="fw-bolder"><img
                        src="../../../assets/Location.svg">Tirupattur</a>
            </div>
            <div class="px-3 me-2">
                <a style="margin: 3px;color: black;" class="fw-bolder" href="mailto:admin@cloudstier.com">
                    <img style="margin: 3px;" src="../../../assets/Secured Letter.svg">admin@cloudstier.com</a>
            </div>
            <div class="px-3 me-2">
                <a img href="tel:+917010600183" style="margin: 3px;"><img src="../../../assets/Mask group.svg">
                    <span style="margin: 3px;color: black;" class="fw-bolder">08035234207 / 7010600183
                    </span></a>
            </div>
        </div> -->

<!-- </div> -->

<!-- </div> -->

<nav class="navbar navbar-expand-lg " style="background-color: transparent;">
    <div class="topbar">
        <div class="top-left">
            <img src="../../../assets/Logo-01.png" routerLink="/servicelist" alt=""
                type="button" loading="lazy" class="logo" />

            <span class="font"><b>One stop destination for all our service needs</b></span>
        </div>
            <div class="top-right">
                <span class="contact">

                    <a href="https://www.google.com/maps/place/Kailasam+Chinnakkanni+Mandabam/@12.4696211,78.536007,19z/data=!3m1!4b1!4m6!3m5!1s0x3bac53e76752df8b:0x158840e2410f8416!8m2!3d12.4696198!4d78.5365542!16s%2Fg%2F11c5xm9ll9"
                        style="color: black;" class="fw-bolder"><img src="../../../assets/Location.svg">Tirupattur</a>
                </span>
                <span class="contact">
                    <a style="margin: 3px;color: black;" class="fw-bolder" href="mailto:admin@cloudstier.com">
                        <img style="margin: 3px;" src="../../../assets/Secured Letter.svg">admin@cloudstier.com</a>
                </span>
                <span class="contact">
                    <a img href="tel:+917010600183" style="margin: 3px;"><img src="../../../assets/Mask group.svg">
                        <span style="margin: 3px;color: black;" class="fw-bolder">08035234207 / 7010600183
                        </span></a>
                </span>
            </div>
       


    </div>



    <!-- Modal -->
    <ng-template #content4 let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Book Request</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="Reqbook">
                <div class="row">
                    <div class="col-xl-11">
                        <div class="mb-3">
                            <div class="has-float-label">
                                <label for="name">Name </label>
                                <input class="form-control" type="text" formControlName="name"
                                    placeholder="Enter Your Name" id="mobile" />
                            </div>
                            <!-- <small style="color: red;" *ngIf="name.invalid && name.touched">Name is required</small>
                                <small style="color: red;" *ngIf=" name.errors.minLength && name.untouched">Name should be in more than 3 digits</small>
    -->
                        </div>
                        <div class="mb-3">
                            <div class="has-float-label">
                                <label for="mobile">Mobile </label>
                                <input class="form-control" type="text"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    onKeyPress="if(this.value.length==10) return false" formControlName="mobile"
                                    placeholder="Enter Mobile Number" id="mobile" />
                            </div>
                            <div>
                                <small style="color: red;" *ngIf="mobile && mobile?.errors?.required && mobile.touched">
                                    Mobile number should be 10 digit</small>
                                <small style="color: red;" *ngIf="mobile?.errors?.minlength && mobile.touched">
                                    Mobile number should be 10 digits</small>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="has-float-label">
                                <label for="mobile">Service Name </label>
                                <input class="form-control" type="text" formControlName="RequestName"
                                    placeholder="Service Name" id="RequestName" />
                            </div>
                            <div>
                                <small style="color: red;"
                                    *ngIf="RequestName && RequestName?.errors?.required && RequestName.touched">
                                    service name is required</small>
                                <small style="color: red;"
                                    *ngIf="RequestName?.errors?.minlength && RequestName.touched">Feild
                                    should be in more than 5 digits</small>
                            </div>
                        </div>
                    </div>
                    <p class="moreoption">
                        <a href="javascript:;" *ngIf="!showMore" (click)="showMore=true">[Show More..]</a>
                        <a href="javascript:;" *ngIf="showMore" (click)="showMore=false">[Show Less..]</a>
                    </p>
                    <div *ngIf="showMore">
                        <div class="mb-3">
                            <span class="has-float-label">
                                <label for="Description">Description </label>
                                <input class="form-control" type="text-area" formControlName="Description"
                                    placeholder="Service Description" id="Description" />
                            </span>
                        </div>
                        <div class="mb-3">
                            <span class="has-float-label">
                                <label for="Address">Address</label>
                                <input class="form-control" type="text" formControlName="Address" placeholder="Address"
                                    id="Address" />
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="d-flex ">
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss(Reqbook)">Cancel</button>
            </div>
            <div class="modal-footer">
                <button type="button" [disabled]="Reqbook.invalid" class="btn btn-primary"
                    (click)="modal.close(Reqbook)">Request Now</button>
            </div>

        </div>
    </ng-template>
    <!-- Container wrapper -->
</nav>
<!-- Navbar -->
<hr class="line">
<div class="row">
    <div class="col-md-5">
        <a class="tablinks" routerLink="" style="margin-left: 148px;color:currentcolor;" class="fw-bolder"
            (click)="onMenuChange('home')" [ngClass]="{'active-menu': menu === 'home'}">Home</a>
        &nbsp;&nbsp;
        <a style="color:currentcolor;" class="fw-bolder" href="#service" (click)="onMenuChange('service')"
            [ngClass]="{'active-menu': menu === 'service'}">Service </a> &nbsp;&nbsp;
        <a style="color:currentcolor;" class="fw-bolder" href="#service-detail" (click)="onMenuChange('service-detail')"
            [ngClass]="{'active-menu': menu === 'service-detail'}">Service
            Details</a> &nbsp;&nbsp;
        <a style="color:currentcolor;" href="#about-us" class="fw-bolder" (click)="onMenuChange('about-us')"
           [ngClass]="{'active-menu': menu === 'about-us'}">About us</a> &nbsp;&nbsp;
        <a style="color:currentcolor;" class="fw-bolder" href="#contact" (click)="onMenuChange('contact')"
            [ngClass]="{'active-menu': menu === 'contact'}">Contacts</a>&nbsp;&nbsp;
    </div>
    <div class="col-md-6">

        <button type="button" class="btn btn-info mr-2" (click)="bookreq(content4)">
            Book Now
        </button>
        
        <button type="submit" class="btn btn-info mr-2 " [routerLink]="['/account/login']">Admin Login</button>
    </div>
</div>