import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface TriggerOption {
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  observer = new Subject<TriggerOption>();

  public subscriber$ = this.observer.asObservable();

  emitData(data: TriggerOption) {
    this.observer.next(data);
}

}
