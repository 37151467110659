import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  sendNotificationToAll(message:any, sendType:any, data: any){
    let url = environment.apiurl2+ `/v1/notification/sendpushnotificationtoall?message=${message}&sendType=${sendType}`
    return this.http.post(url, data);
  }
  notReachableAlert(ReqId){
    let url = environment.apiurl2+ `/v1/notification/responsefornotreachable?requestID=${ReqId}`
    return this.http.post(url,'');
  }

  addreminder(payload){
    let url = environment.apiurl2 + `/v1/notification/addremainder`
    return this.http.post(url, payload)
  }
  getreminderdate(ReqId){
    let url = environment.apiurl2 + `/v1/notification/getalldescriptionbyid?requestID=${ReqId}`;
    return this.http.get(url);
  }
  getallreminderdate(){
    let url = environment.apiurl2 + `/v1/notification/getallremainderdate`;
    return this.http.get(url);
  }
  Marknotificationasread(ReqId){
    let url = environment.apiurl2 + `/v1/notification/marknotificationasread?requestID=${ReqId}`;
    return this.http.post(url, '');
  }
  getalluser(){
    let url = environment.apiurl2+`/v1/users/getallusers`
    return this.http.get(url);
  }

  adduserremainderdate(payload){
    let url = environment.apiurl2 + `/v1/notification/adduserremainderdate`;
    return this.http.post(url, payload);
  }

  getallUserRemaindersbydate(remainderdate){
    let url = environment.apiurl2 + `/v1/notification/getalluserremaindersbydate?RemainderDate=${remainderdate}`;
    return this.http.get(url);
  }

  UserNotificationasread(userid){
    let url = environment.apiurl2 + `/v1/notification/usernotificationasread?UserID=${userid}`;
    return this.http.post(url,'');
  }

  getallrequestremaindersbydate(remainderdate){
    let url = environment.apiurl2 + `/v1/notification/getallrequestremaindersbydate?RemainderDate=${remainderdate}`;
    return this.http.get(url);
  }

  getallUserDescriptionbyuserid(userId){
    let url = environment.apiurl2 + `/v1/notification/getalluserdescriptionbyuserid?UserID=${userId}`;
    return this.http.get(url);
  }
}
