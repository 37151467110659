import { Component } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AllservicesService } from 'src/app/services/allservices.service';
import { CreatereqService } from 'src/app/services/createreq.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-servicename',
  templateUrl: './servicename.component.html',
  styleUrls: ['./servicename.component.scss']
})
export class ServicenameComponent {
  details: any; 
  popularservices: any;
 serviceCategory: any;
 serviceCateg: any;
 serviceid:number;
 id:number;
 Sname:any;
 NotFound:boolean=false;
 loading:boolean=false;
 private itemsPerSlide: number;
 servicebyid:any;
 showMore:boolean = false;
// initial value


slides = [
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/1.jpg'},
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/2.jpg'},
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/3.jpg'},
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/4.jpg'},
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/5.jpg'},
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/6.jpg'},
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/7.jpg'},
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/8.jpg'},
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/1.jpg'},
  {image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/2.jpg'}
];


itemsToShow = 4; 
Reqbook: FormGroup; 

 constructor(private service : AllservicesService,private modalService: NgbModal,
  private route : ActivatedRoute, private allservices: AllservicesService ,
   config: NgbCarouselConfig,private formBuilder: FormBuilder,private createreq: CreatereqService){
  config.interval = 2000;
  config.wrap = true;
  config.keyboard = true;
  config.pauseOnHover = true;

  
 }

//  itemsToShow: number = 4; 
// allItems = [];

// showMore() {
//   this.itemsToShow += 4; 
// }
 
//  carouselConfig = {
//   slidesToShow: 5,
//   slidesToScroll: 1,
//   dots: false,
//   arrows: true
// };
 

ngOnInit(): void {

 let id = this.route.snapshot.queryParams['id'];
 this.Sname = this.route.snapshot.queryParams['category']

 this.Reqbook = this.formBuilder.group({
//   name: ['', [Validators.minLength(3), Validators.maxLength(80)]],
//   mobile: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
     RequestName: ['', [Validators.minLength(3), Validators.maxLength(80)]]  
});





 this.loading=true;
 this.allservices.getservicebyid(id).subscribe((res) =>{

 this.servicebyid = res['result']
 this.loading=false;
 this.Reqbook.patchValue({
  RequestName: this.servicebyid?.serviceName  
});
  if (res['status'] == 0){
     this.NotFound= true;
     }
 })

 

this.loading=true;
 this.allservices.getallservicebyservicecategoryid(id).subscribe((res) =>{

 this.serviceCategory = res['result']
 this.loading=false;
  if (res['status'] == 0){
     this.NotFound= true;
  }
 })
 
 
 this.loading=true;
 this.allservices.getpopularservices().subscribe((res)=>{
  console.log(res)
 this.popularservices = res['result']
 this.loading=false;
 console.log(this.popularservices)
  if (res['status'] == 0){
     this.NotFound= true;
  }
 })

 

 
//  const serviceDetails =
//   [
//     {id: 1, service: 'Cleaning', Description :' There are many variations1 of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable'
//     ,
//     img:"assets/IMG-20221216-WA0001.jpg", servicephotos:[{i:1,photo:'assets/IMG-20221216-WA0001.jpg',alt:''},
//     {i:1,photo:'assets/IMG-20221216-WA0001.jpg',alt:''}]},
//     {id: 2, service: 'Repair',Description :' There are many variations2 of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable'
//     ,img:"assets/IMG-20221216-WA0003.jpg" ,servicephotos:[{i:1,photo:'assets/IMG-20221216-WA0001.jpg',alt:''},
//     {i:1,photo:'assets/IMG-20221216-WA0003.jpg',alt:''}]},
//     {id: 3, service: 'Shifting Service', Description :' There are many variations3 of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable'
//     ,img:"assets/IMG-20221216-WA0004.jpg",servicephotos:[{i:1,photo:'assets/IMG-20221216-WA0001.jpg',alt:''},
//     {i:2,photo:'assets/IMG-20221216-WA0004.jpg',alt:''}]},
//     {id: 4, service: 'Pest Control Service',Description :' There are many variations4 of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable'
//     ,img:"assets/IMG-20221216-WA0005.jpg",servicephotos:[{i:1,photo:'assets/IMG-20221216-WA0001.jpg',alt:''},
//     {i:2,photo:'assets/IMG-20221216-WA0005.jpg',alt:''}]}
// ];
//   this.details = serviceDetails
//   console.log(this.details)
//  this.name = name;
 if(id){
  this.serviceid=id
 }

}
bookreq(content4) {
  console.log(this.Reqbook)
  this.modalService.open(content4, { ariaLabelledBy: 'modal-basic-title' }).result.then((bookreq) => {

    if(this.Reqbook.valid){
       let paylod={
        "mobile": this.Reqbook.controls.mobile.value,
        "name": this.Reqbook.controls.name.value,
        "RequestName":this.Reqbook.controls.RequestName.value,
        "Description":this.Reqbook.controls.Description.value,
        address :{
        "addressLine1":this.Reqbook.controls.Address.value
        }
       }
       console.log(paylod)
       this.service.createreq(paylod).subscribe(res=>
        {
         console.log(res)
         if(res['status']==1){
         Swal.fire(res['message'])
         this.Reqbook.reset();
         }  
         })}
        else{
          this.Reqbook.reset();
        }
  });
}



get name(){
  return this.Reqbook.get('name')
}
get mobile(){
  return this.Reqbook.get('mobile') 
}
get RequestName(){
  return this.Reqbook.get('RequestName')
}
form(){
  this.Reqbook.reset();
}

// onshowMore(){
//   this.showMore=true
// }
}