import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AllservicesService {
  patchValue(arg0: { serviceName: string; }) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }

  allServices(){
    let url = environment.apiurl2 + `/v1/services/getservices`;
    return this.http.get(url);
  }
  createreq(payload){ 
    let url = environment.apiurl2 + `/v5/users/requestbyguestuser`;
    return this.http.post(url,payload)
  }
  getservicecatliist(){
    let url = environment.apiurl2+ `/v1/services/getallservicecategories`
    return this.http.get(url);
  }
  getpopularservices(){
    // https://servicedev.oneclick2serve.in/api/v1/services/getallpopularservices
    let url= environment.apiurl2+ `/v1/services/getallpopularservices`;
    return this.http.get(url);
  }
  updateservice(data: any, id) {
    let url = environment.apiurl2 + `/v1/services/updateservices?id=${id}`;
    return this.http.put(url, data);
  }

  getallservicesforweb(){
    let url=  environment.apiurl2 + `/v1/services/getallservicesforweb`;
    return this.http.get(url)
  }
  updateservicepricebyserviceid(data:any){
    let url =  environment.apiurl2 + `/v1/services/updateservicepricebyserviceid`;
    return this.http.put(url, data);
  }

  getallcompletedrequestswithratings(){
     let url = environment.apiurl2 + `/v1/request/getallcompletedrequestswithratings`;
     return this.http.get(url)
  }
  getallservicebyservicecategoryid(id):Observable <any>{
    let url = environment.apiurl2 + `/v1/services/getallservicebyservicecategoryid?categoryID=${id}`
    return this.http.get(url)
  }

  getservicebyid(id:any){
    let url=environment.apiurl2 + `/v1/services/getservicebyid?id=${id}`;
    return this.http.get(url);
  }
  getpopularservicesbyid(id){
    let url= environment.apiurl2+ `/v1/services/getallpopularservices?categoryID=${id}`;
    return this.http.get(url);
  }
  getalloffercontent(){
    let url=environment.apiurl2+`/v1/offercontent/getalloffercontent`;
    return this.http.get(url);
  }
  updateoffercontentbyid(data:any){
    let url=environment.apiurl2+`/v1/offercontent/updateoffercontentbyid`;
    return this.http.put(url, data); 
  }
  
  getrequestcount(){
    let url = environment.apiurl2+`/v1/admin/getrequestcount`;
    return this.http.get(url);
  }

  getallbuildingbystatus(status: number){
    let url = environment.apiurl2+`/v1/tolet/getallbuildingbystatus?Status=${status}`;
    return this.http.get(url);
  }

  updatebuildingstatus(buildingId: number, status:number){
    let url = environment.apiurl2+`/v1/tolet/updatebuildingstatus?buildingID=${buildingId}&statusID=${status}`;
    return this.http.post(url,buildingId);
  }

  getallbuildingsbybuildingid(buildingId: any){
    let url = environment.apiurl2+`/v1/admintolet/getallbuildingsidbyadmin?BuildingID=${buildingId}`;
    return this.http.get(url,buildingId);
  }

  getallapartmenttype(){
    let url = environment.apiurl2+`/v1/tolet/getallapartmenttype`;
    return this.http.get(url);
  }

  getallfacingtype(){
    let url = environment.apiurl2+`/v1/tolet/getallfacingtype`;
    return this.http.get(url);
  }

  gethousetypes(){
    let url = environment.apiurl2+`/v1/tolet/gethousetypes`;
    return this.http.get(url);
  }

  getallpropertyagetype(){
    let url = environment.apiurl2+`/v1/tolet/getallpropertyagetype`;
    return this.http.get(url);
  }
//new
  getallmaintanencetype(){
    let url = environment.apiurl2+`/v1/tolet/getallmaintanencetype`;
    return this.http.get(url);
  }

  getallrentfurnishingtype(){
    let url = environment.apiurl2+`/v1/tolet/getallrentfurnishingtype`;
    return this.http.get(url);
  }

  getallparkingtype(){
    let url = environment.apiurl2+`/v1/tolet/getallparkingtype`;
    return this.http.get(url);
  }

  getalltenenttype(){
    let url = environment.apiurl2+`/v1/tolet/getalltenenttype`; 
    return this.http.get(url);
  }

  getallpropertyshowtype(){
    let url = environment.apiurl2+`/v1/tolet/getallpropertyshowtype`; 
    return this.http.get(url);
  }

  getallwatersupplytype(){
    let url = environment.apiurl2+`/v1/tolet/getallwatersupplytype`; 
    return this.http.get(url);
  }

  getallamentitiestype(){
    let url = environment.apiurl2+`/v1/tolet/getallamentitiestype`; 
    return this.http.get(url);
  }

  getallrestrictiontype(){
    let url = environment.apiurl2+`/v1/tolet/getallrestrictiontype`; 
    return this.http.get(url);
  }

  getallpropertytype(){
    let url = environment.apiurl2+`/v1/tolet/getallpropertytype`; 
    return this.http.get(url);
  }

  getallpropertyadtype(){
    let url = environment.apiurl2+`/v1/tolet/getallpropertyadtype`; 
    return this.http.get(url);
  }

  getallcommercialpropertytype(){
    let url = environment.apiurl2+`/v1/tolet/getallcommercialpropertytype`; 
    return this.http.get(url);
  }

  getallcommercialbuildingtype(){
    let url = environment.apiurl2+`/v1/tolet/getallcommercialbuildingtype`; 
    return this.http.get(url);
  }

  getallpropertystatus(){
    let url = environment.apiurl2+`/v1/tolet/getallpropertystatus`; 
    return this.http.get(url);
  }

  getallpropertyavailabilitytype(){
    let url = environment.apiurl2+`/v1/tolet/getallpropertyavailabilitytype`; 
    return this.http.get(url);
  }

  getallareas(){
    let url = environment.apiurl2+`/v1/tolet/getallareas`; 
    return this.http.get(url);
  }
  getrenttypes(){
    let url = environment.apiurl2+`/v1/tolet/getrenttypes`; 
    return this.http.get(url);
  }

  updateallbuildingdetails(buildingid: any, payload: any){
    let url = environment.apiurl2+`/v1/tolet/updateallbuildingdetails?BuildingID=${buildingid}`; 
    return this.http.put(url, payload);
  }
}
