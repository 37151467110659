import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserserviceService {

  constructor(private http: HttpClient) { }

  registerUser(data: any) {
    let url = environment.apiurl2 + "/v1/users/registration";
    // let url = "https://servicedev.oneclick2serve.in/api/v1/users/registration";
    return this.http.post(url, data);
  }

  loginuser(data: any) {
    let url = environment.apiurl2 + "/v1/users/jwtauthentication";
    // let url = "https://servicedev.oneclick2serve.in/api/v1/users/authenticateuser";
    return this.http.post(url, data);
  }

  loggedIn() {
    return !!localStorage.getItem('token')
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userid");
  }

  getid() {
    let id = localStorage.getItem("userid");
    let url = environment.apiurl2 + "/v1/employee/getemployeebyid?id=" + id;
    return this.http.get(url);
  }

  updatedetail(data: any) {
    let url = environment.apiurl2 + "/v1/users/userupdate";
    return this.http.post(url, data);
  }
  changepass(empMobile,data){
    let url = environment.apiurl2 + `/v1/employee/changeemployeepassword?mobile=${empMobile}`
    return this.http.put(url, data)

  }
}
