<div>
<div class="Maincontainer">
    <div id="about-us">
        <div id="contact">

            <div class="bg">
    
                <div class="container">
                    <div class="row">
                        <div class="col-4">
                            <span class="hea fw-bold">
                                About us
                            </span>
                        </div>
                        <div class="col-4">
                            <span class="hea fw-bold">
                                Important links
                            </span>
                        </div>
                        <div class="col-4">
                            <div class="col-md">
                                <span class="hea fw-bold">
                                    Contact
                                </span>
                            </div>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-4">
                            <p class="cont">
                                Your ultimate one stop destination for all your needs!
                                We are a leading online portal dedicated to providing 
                                reliable and efficient servicing services for all your needs.
                                With a team of highly skilled professionals and cutting-edge technology,
                                we strive to make your experience seamless and hassle-free.
                                Whether you need assistance with home maintenance,
                                electronic repairs, or vehicle servicing, OneClick(product by Cloudstier Solutions Pvt.Ltd.) has got you covered.
                            </p>
                            <ul class="u ">
                                <li class="l px-3">
                                    <a href="https://wa.me/+917010600183?text=hi">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            class="bi bi-whatsapp" viewBox="0 0 16 16">
                                            <path
                                                d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                        </svg>
                                    </a>
    
                                </li>
                                <li class="l px-3">
                                    <a href="https://www.instagram.com/oneclick_homeservice_tpt/">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            class="bi bi-instagram" viewBox="0 0 16 16">
                                            <path
                                                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                        </svg>
                                    </a>
                                </li>
                                <li class="l px-3">
                                    <a href="https://www.facebook.com/oneclicktoserve/?mibextid=ZbWKwL">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            class="bi bi-facebook" viewBox="0 0 16 16">
                                            <path
                                                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-4">
                            <div>
                                <li style="list-style-type: none;margin-left: -2rem;"><br>
                                    <ul class="lis">
                                        <span class="sp" (click)="viewpopup(policy)">Return policy</span>
                                    </ul>
                                    <ul class="lis">
                                        <span class="sp" (click)="viewpopup(privacy)">Privacy Policy</span>
                                    </ul>
                                    <ul class="lis">
    
                                        <span class="sp" (click)="viewpopup(pricing)">Pricing </span>
                                    </ul>
                                    <ul class="lis">
                                        <span class="sp" (click)="viewpopup(refund)">Refund and Cancellation policy</span>
                                    </ul>
                                    <ul class="lis">
                                        <span class="sp" (click)="viewpopup(shipping)">Shipping and Delivery Timeline</span>
                                    </ul>
                                    <ul class="lis">
                                        <span class="sp" (click)="viewpopup(terms)">Terms and conditions</span>
                                    </ul>
                                </li>
                            </div>
    
                        </div>
                        <div class="col-4">
                            <div>
                                <li style="list-style-type: none; margin-left: -2rem;">
                                    <ul class="lis-1">
                                        <div>
                                            <img style="color: black; max-width: 20px;" src="../../../assets/icon/Phone.png"
                                                alt="phone"> &nbsp;&nbsp;
                                            <span style="color: black;" class="head">Phone</span>
                                        </div>
    
                                        <div>
                                            <a href="tel:+917010600183" class="sp">+917010600183</a><br>
                                        </div>
                                    </ul>
    
                                    <ul>
                                        <div>
                                            <img src="../../../assets/icon/Envelope.png" alt="email"> &nbsp;&nbsp;
                                            <span style="color: black;" class="head">Email</span>
                                        </div>
                                        <div>
                                            <a href="mailto:admin@cloudstier.com" class="sp">admin@cloudstier.com</a><br>
                                        </div>
                                    </ul>
    
                                    <ul>
                                        <img src="../../../assets/icon/Location.png" alt="location"> &nbsp;&nbsp;
    
                                        <span style="color: black;" class="head">Address</span>
                                        <div >
                                            <a href="https://www.google.com/maps/place/Kailasam+Chinnakkanni+Mandabam/@12.4696211,78.536007,19z/data=!3m1!4b1!4m6!3m5!1s0x3bac53e76752df8b:0x158840e2410f8416!8m2!3d12.4696198!4d78.5365542!16s%2Fg%2F11c5xm9ll9"
                                                class="sp">Cloudstier Solutions<br>Private Limited,<br>
                                                kasinayakanpatti,<br>Dharumapuri road,<br>Tirupattur<br>635 601<br>
                                            <!-- <span class="sp" style="margin-left: 12px;">Private Limited,</span><br>
                                            <span class="sp" style="margin-left: 12px;">kasinayakanpatti,</span><br>
                                            <span class="sp" style="margin-left: 12px;">Dharumapuri
                                                road,</span><br>
                                            <span class="sp" style="margin-left: 12px;">Tirupattur</span><br>
                                            <span class="sp" style="margin-left: 12px;">635 601</span>--></a><br> 
                                        </div>
                                        <!-- <span class="sp" style="margin-left: 29px;">Kasinayakkan patti</span>
                            <span class="sp" style="margin-left: 29px;">Tirupattur</span> -->
                                    </ul>
    
    
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    
</div>
</div>

<ng-template #policy let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Return policy</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p>
            At OneClick(product by Cloudstier Solutions Pvt.Ltd.), we value your satisfaction. 
            If for any reason you are not completely happy with our service,
            we offer a hassle-free re-servicing policy. 
            Simply reach out to our customer support team within 2 days of service,
            and we will guide you through the process. Please note that certain conditions may apply. 
            Your feedback is essential to us,
            and we are committed to resolve any concerns you may have.</p>
    </div>
</ng-template>

<ng-template #privacy let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Privacy Policy</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p> Your privacy is of utmost importance to us at OneClick(product by Cloudstier Solutions Pvt.Ltd.).
            We have implemented stringent security measures to protect
            your personal information and ensure it remains confidential.
            We only collect necessary data to process your service and provide a personalized experience.
            Rest assured that we do not share your details with third parties unless required by law.</p>
    </div>
</ng-template>

<ng-template #pricing let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Pricing</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p>At OneClick(product by Cloudstier Solutions Pvt.Ltd.), we believe in transparency. 
            Our pricing is fair and competitive, providing you with
            excellent value for the services we offer. Please note 
            that prices may vary depending on the specific service 
            and service provider you choose. We reserve the right to modify prices at any time,
            but any changes will be communicated to you in advance.</p>
    </div>
</ng-template>

<ng-template #refund let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Refund and Cancellation policy</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p> We understand that circumstances may change, 
            and sometimes cancellations and refunds are necessary. 
            If you wish to cancel your service or request a refund, please 
            contact our customer support team as soon as possible. Cancellation 
            and refund policies may vary depending on the service provider and 
            the specific terms and conditions associated with the service. 
            We will do our best to assist you and ensure 
            a smooth process in line with our refund and cancellation policy.</p>
    </div>
</ng-template>

<ng-template #shipping let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Shipping and Delivery Timeline</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p> For any available services on our platform, 
            we strive to serve for your service in a timely manner. 
            The delivery timeline may vary depending on your location, 
            the availability of the products and services. We posses our 
            own delivery and also we partner with reputable logistics providers 
            to ensure your service reaches you safely and efficiently. 
            Estimated time will be provided at the time of checkout, 
            and we will keep you informed about the progress of your service.</p>
    </div>
</ng-template>

<ng-template #terms let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Terms and conditions</h4>
        <button type="button" class="btn-close" aria-label="close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body; scrollable">
        <h5>Acceptance of Terms</h5><br>
        <p> By accessing and using the One Click(product by Cloudstier Solutions Pvt.Ltd.) website ("One Click 2 Serve"),
            you agree to be bound by the following terms and conditions.
        </p><br>
        <h5>Use of the Website</h5><br>
        <p> You may use the website for personal and non-commercial purposes only. You may not modify, copy,
            distribute, transmit, display, perform, reproduce, publish, license, create derivative works from,
            transfer, or sell any information, software, products, or services obtained from the website.erve"),
            you agree to be bound by the following terms and conditions.
        </p><br>
        <h5>User Registration</h5><br>
        <p> In order to access certain features of the website, you may be required to create a user account. You
            agree to provide accurate and complete information during the registration process and to keep your
            account credentials confidential. You are responsible for all activities that occur under your account.
        </p><br>
        <h5>Intellectual Property</h5><br>
        <p> The website and its content are protected by intellectual property laws and are the property of One
            Click and its licensors. You may not use, reproduce, or distribute any copyrighted material without the
            prior written consent of the respective owner.
        </p><br>
        <h5>Privacy Policy</h5><br>
        <p> One Click(product by Cloudstier Solutions Pvt.Ltd.) respects your privacy and is committed to protecting your personal information. Please refer
            to the website's Privacy Policy for information on how your data is collected, used, and disclosed.
        </p><br>
        <h5>Modification and Termination</h5><br>
        <p> One Click(product by Cloudstier Solutions Pvt.Ltd.) reserves the right to modify, suspend, or terminate the website or any part thereof, at any
            time and for any reason, without prior notice. One Click may also change these terms and conditions from
            time to time without notice. It is your responsibility to review the most current version of the terms
            and conditions.
        </p><br>
        <h5>Governing Law</h5><br>
        <p> These terms and conditions shall be governed by and construed in accordance with the laws of the
            jurisdiction in which One Click(product by Cloudstier Solutions Pvt.Ltd.) is located, without regard to its conflict of law provisions.
        </p><br>
        <h5>Entire Agreement</h5><br>
        <p>These terms and conditions constitute the entire agreement between you and One Click(product by Cloudstier Solutions Pvt.Ltd.) regarding the
            website and supersede all prior agreements and understandings.

            By using the One Click(product by Cloudstier Solutions Pvt.Ltd.) website, you acknowledge that you have read, understood, and agreed to these
            terms and conditions.
        </p><br>
    </div>
</ng-template>