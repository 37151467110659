import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreatereqService {

  constructor(private http: HttpClient) { }

  getcreatereq() {
    let url = environment.apiurl2 + "/v1/request/getallunassignedrequest";
    return this.http.get(url);
  }

  addreq(data: any) {
    let url =  environment.apiurl2 + `/v1/users/requestbyguestuser`;
    return this.http.post(url, data)
  }

  updatereq(data: any, reqID) {
    let url = environment.apiurl2 + `/v1/request/updaterequest?requestId=${reqID}`;
    return this.http.put(url, data);
  }

  deletereq(id: any) {
    let url = environment.apiurl2 + `/v1/request/deleterequest?requestID=${id}`
    return this.http.delete(url);
  }

 getaddressbyid(id:any){
  let url=environment.apiurl2+`/v1/users/getaddressbyuserid?userId=${id}`;
  return this.http.get(url);
 }

  getrequestbyid(id: any) {
    let url = environment.apiurl2 + `/v1/request/getrequestbyid?id=${id}`;
    return this.http.get(url);
  }

  getOpenRequest(type: any) {
    let id = localStorage.getItem("userid");
    let url = environment.apiurl2 + `/v1/request/gettaskbyemployeeid?id=${id}&Type=${type}`;
    return this.http.get(url);
  }

  addReqTech(reqID, reqTaskID, techID) {
    let id = localStorage.getItem("userid");
    let url = environment.apiurl2 + `/v1/request/addrequesttaskworkers?RequestID=${reqID}&RequestTaskID=${reqTaskID}&EmployeeID=${id}&TechnicianID=${techID}`;
    return this.http.post(url, '');
  }

  getTechWorktype(workTypeID) {
    let url = environment.apiurl2 + `/v1/worktype/getalltechnicianworktype?id=${workTypeID}`;
    return this.http.get(url);
  }

  rejectingRequest(finalReqID, data) {
    let url = environment.apiurl2 + `/v1/request/rejectrequest?requestId=${finalReqID}`;
    return this.http.post(url, data);
  }

  getRejectReason(){
    let url = environment.apiurl2 + '/v4/request/getrejectreason?type=3'
    return this.http.get(url)
  }

  findReqID(reqID){
    let url = environment.apiurl2 + `/v1/request/getallstagebyrequestid?RequestId=${reqID}`;
    return this.http.get(url);
  }

  updateAddress(reqID, addressID, userID){
    let url = environment.apiurl2 + `/v1/users/updateaddresstorequest?reqID=${reqID}&addressID=${addressID}&userID=${userID}`
    return this.http.put(url, '')
  }

  addAddress(data: any){
    let url = environment.apiurl2 + `/v1/users/addaddressweb`
    return this.http.post(url, data)
  }

  transferReq(data: any, reqID){
    let url = environment.apiurl2 + `/v1/request/updaterequesttoanotherperson?reqID=${reqID}`
    return this.http.post(url, data)
  }

}
