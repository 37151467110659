import { Injectable } from '@angular/core';
import {HttpRequest,HttpHandler, HttpEvent,HttpInterceptor} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OneclickInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // console.log("Interceptor fired*****")
    const API_KEY = localStorage.getItem("token")
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${API_KEY}`
      }
    })
    return next.handle(request).pipe(catchError(err => {
      console.log(err)
      if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.router.navigate(['/account/login']);
      }
      
      return throwError(err);
  }));
  }
}
