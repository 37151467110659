import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { NgbNavModule, NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ExtrapagesModule } from './extrapages/extrapages.module';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserserviceService } from './services/userservice.service';
import { AuthGuard } from './core/guards/auth.guard';
import { NgxPaginationModule } from 'ngx-pagination';
import { ServiceslistComponent } from './oneclickservice/serviceslist/serviceslist.component';
import { ServicenameComponent } from './oneclickservice/servicename/servicename.component';
import { ServicetopbarComponent } from './oneclickservice/servicetopbar/servicetopbar.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ServicebookingComponent } from './oneclickservice/servicebooking/servicebooking.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServicefooterComponent } from './oneclickservice/servicefooter/servicefooter.component';
// import { NgImageSliderModule } from 'ng-image-slider';
import { NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';







if (environment.defaultauth === 'firebase') {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expressio
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ServiceslistComponent,
    ServicenameComponent,
    ServicetopbarComponent,
    ServicebookingComponent,
    ServicefooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    // MdbCollapseModule,
    // NgImageSliderModule
    NgbPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    NgSelectModule

  ],
  bootstrap: [AppComponent],
  providers: [ UserserviceService, AuthGuard ,
  ],
}) 
export class AppModule { }
