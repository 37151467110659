import { Component } from '@angular/core';
import { FormArray, FormBuilder,FormControl,FormGroup, MaxValidator, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AllservicesService } from 'src/app/services/allservices.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-servicetopbar',
  templateUrl: './servicetopbar.component.html',
  styleUrls: ['./servicetopbar.component.scss']
})
export class ServicetopbarComponent {
  Reqbook: FormGroup; 
  showMore = false;
  menu = 'home';
  constructor(private service : AllservicesService, private formBuilder: FormBuilder ,private modalService: NgbModal){}
  ngOnInit(){
    // this.service.getservicecatliist().subscribe(servicescat=>
    //   {
    //     console.log(servicescat)
    //   })
    //   this.service.allServices().subscribe(serviceslist=>
    //     {
    //       console.log(serviceslist)
    //     })
    this.Reqbook = this.formBuilder.group({
      mobile: new FormControl('',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]),
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]) ,
      RequestName : new FormControl('',[Validators.required , Validators.minLength(5), Validators.maxLength(50)]) ,
      Description : new FormControl('',[ Validators.minLength(3), Validators.maxLength(200)]) ,
      Address : new FormControl('',[ Validators.minLength(3), Validators.maxLength(20)]) 
    })
  }
  
  bookreq(content4) {
    console.log(this.Reqbook)
    this.modalService.open(content4, { ariaLabelledBy: 'modal-basic-title' }).result.then((bookreq) => {

      if(this.Reqbook.valid){
         let paylod={
          "mobile": this.Reqbook.controls.mobile.value,
          "name": this.Reqbook.controls.name.value,
          "RequestName":this.Reqbook.controls.RequestName.value,
          "Description":this.Reqbook.controls.Description.value,
          address :{
          "addressLine1":this.Reqbook.controls.Address.value
          }
         }
         console.log(paylod)
         this.service.createreq(paylod).subscribe(res=>
          {
           console.log(res)
           if(res['status']==1){
           Swal.fire(res['message'])
           this.Reqbook.reset();
           }  
           })}
          else{
            this.Reqbook.reset();
          }
    });
  }

  get name(){
    return this.Reqbook.get('name')
  }
  get mobile(){
    return this.Reqbook.get('mobile') 
  }
  get RequestName(){
    return this.Reqbook.get('RequestName')
  }
  form(){
    this.Reqbook.reset();
  }

  onMenuChange(name: string){
    this.menu = name;
  }
}
